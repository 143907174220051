import Vue from "vue";
import VueRouter from "vue-router";
import { AuthRequired, NotAuthRequired } from "../utils/AuthRequired";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    beforeEnter: NotAuthRequired,
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/",
    name: "Home",
    beforeEnter: AuthRequired,
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "overview",
        name: "Overview",
        component: () => import("../views/Overview.vue"),
      },
      {
        path: "frames",
        name: "Frames",
        component: () => import("../views/Frames.vue"),
      },
      {
        path: "banners",
        name: "Banners",
        component: () => import("../views/Banners.vue"),
      },
      {
        path: "popup-image",
        name: "Popup Image",
        component: () => import("../views/PopupImage.vue"),
      },
      {
        path: "protocol",
        name: "Protocol Edit",
        component: () => import("../views/Protocol.vue"),
      },
      {
        path: "netaji-daily",
        name: "Netaji Daily",
        component: () => import("../views/DailyImageFolder.vue"),
      },
      {
        path: "netaji-daily/folder-images/:folderId",
        name: "Netaji Daily Folder Images",
        component: () => import("../views/DailyImageFolderImageV2.vue"),
      },
      {
        path: "templates",
        name: "Templates",
        component: () => import("../views/Templates.vue"),
      },
      {
        path: "customers",
        name: "Customers",
        component: () => import("../views/Customers.vue"),
      },
      {
        path: "promo_code",
        name: "Promo code",
        component: () => import("../views/Promo.vue"),
      },
      {
        path: "political_party",
        name: "Political party",
        component: () => import("../views/Political.vue"),
      },
      {
        path: "transaction",
        name: "Transaction",
        component: () => import("../views/Transaction.vue"),
      },
      {
        path: "notification",
        name: "Notification",
        component: () => import("../views/Notification.vue"),
      },
      {
        path: "profile",
        name: "Profile",
        component: () => import("../views/Profile.vue"),
      },
      {
        path: "review",
        name: "Review",
        component: () => import("../views/Review.vue"),
      },
      {
        path: "contactform",
        name: "Contact Form",
        component: () => import("../views/ContactForm.vue"),
      },
      {
        path: "joincommunity",
        name: "Community Join",
        component: () => import("../views/CommunityQuery.vue"),
      },
      {
        path: "suggestposter",
        name: "Suggest A Poster",
        component: () => import("../views/SuggestAPoster.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
